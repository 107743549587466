.photo-gallery {
    color:#313437;
    background-color:#fff;
  }
  
  .photo-gallery p {
    color:#7d8285;
  }
  
  .photo-gallery h2 {
    font-weight:bold;
    margin-bottom:40px;
    padding-top:40px;
    color:inherit;
  }
  
  @media (max-width:767px) {
    .photo-gallery h2 {
      margin-bottom:25px;
      padding-top:25px;
      font-size:24px;
    }
  }
  
  .photo-gallery .intro {
    font-size:16px;
    margin:0 auto 40px;
  }
  
  .photo-gallery .intro p {
    margin-bottom:0;
  }
  
  .photo-gallery .photos {
    padding-bottom:20px;
  }

  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }

  .photo-gallery .photos .item:hover img{
    transform: scale(1.5);
    transition: transform .4s; /* Animation */
  }

  .photo-gallery .photos .item:hover .middle{
    opacity: 1;
    transform: scale(1.5);
  }
  
  .photo-gallery .item {
    padding-bottom:30px;
    position: relative;
  }

  .carousel-caption {
    color: #2b2d2e;
  }


  .item {
    overflow: hidden;
    display: grid;
    grid-template-columns: 1;
    grid-template-rows: 1;
  }
  .item img {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  