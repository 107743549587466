body {
  margin: 0;
  font-family: 'Quicksand';font-size: 22px;
}

header {
  background-color: rgb(51, 51, 51);
}

.bg-milkshake{
  background-color: rgb(221, 217, 214);;
}
.navbar-brand{
  font-family: -Quicksand, sans-serif;
  font-size: 22px;
  font-weight: 400;
}

p.lead{
  font-size: 16px;
}

.navbar-nav {
  padding-left: 40px;
  .nav-link {
        color: rgb(169, 169, 169) !important;
        &.active{
          color: rgba(255, 255, 255, 0.874) !important;
        }
      }
    }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.display-6 {
  font-size: 38px;
      color: rgb(89, 89, 89);
}

.display-7 {
  font-size: 16px;
}

.contact-section{
  h3 {
    font-weight: bold;
    font-size: 16px;
  }

  p{
    font-size:16px;
  }


}

footer {
  background-color: rgb(51, 51, 51);
  
  p{
    color: rgb(169, 169, 169);
    font-size:12px;
    text-align: center;
    padding:10px;

    span {
      text-transform: uppercase;
    }
  }
}
